<template>
	<div class="sport-park-mobile" v-if="isMobile">
		<img src="../assets/img/ydgy.png" class="img-banner">
		<div class="main-content">
			<div class="row-me tab-div">
				<div class="column-me center-all left-div">
					<div class="title1">卡丁车</div>
					<div class="title2">GO-KART</div>
				</div>
				<div class="row-me row-center flex-wrap flex1 padding-bottom10 padding-top10">
					<div class="title0" :class="{'title0-active':tabIndex===0}" @click="clickIndex(0)">竞速馆</div>
					<div class="tab-line"></div>
					<div class="title0" :class="{'title0-active':tabIndex===1}" @click="clickIndex(1)">运动公园</div>
					<!-- <div class="tab-line"></div> -->
					<div class="title0" :class="{'title0-active':tabIndex===2}" @click="clickIndex(2)">室外运动场</div>
<!--					<div class="tab-line"></div>-->
<!--					<div class="title0" :class="{'title0-active':tabIndex===3}" @click="clickIndex(3)">教培馆</div>-->
<!--					&lt;!&ndash; <div class="tab-line"></div> &ndash;&gt;-->
<!--					<div class="title0" :class="{'title0-active':tabIndex===4}" @click="clickIndex(4)">体能拓展馆</div>-->
				</div>
			</div>
			<!-- 竞速馆 -->
			<div class="column-me row-center" v-if="tabIndex===0">
				<div class="row-me center-all margin-top60">
					<img src="../assets/img/title_line.png" class="img-line">
					<div class="column-me row-center margin-left10 margin-right10">
						<div class="title">卡丁车室内竞速馆</div>
					</div>
					<img src="../assets/img/title_line.png" class="img-line">
				</div>
				<div class="subtitle">GO-KART INDOOR RACE HALL</div>
				<div class="column-me row-center width100Per margin-top50">
					<div class="column-me">
						<div class="title11">项目介绍</div>
						<div class="title22">AN INTRODUCTION TO THE PROJECT</div>
						<div class="row-me row-center margin-bottom20">
							<div class="line-yellow1"></div>
							<div class="line-yellow2"></div>
						</div>
						<div class="row-me margin-top10">
							<img src="../assets/img/1.png" class="img-sort">
							<div class="intro-text">客户群体：室内卡丁车竞速馆是面向3--12岁儿童开放的单一卡丁车运动体验馆</div>
						</div>
						<div class="row-me margin-top10">
							<img src="../assets/img/2.png" class="img-sort">
							<div class="intro-text">经营业态：场馆主要经营以卡丁车驾驶体验为主的相关业态，通过不同的场景道具来营造驾驶氛围，通过圈速系统来测算驾驶者的驾驶水平</div>
						</div>
						<div class="row-me margin-top10">
							<img src="../assets/img/3.png" class="img-sort">
							<div class="intro-text">场馆位置：场馆主要建在商业综合体内或者商业综合体中庭位置，建馆面积通常在300平左右</div>
						</div>
						<div class="row-me margin-top10">
							<img src="../assets/img/4.png" class="img-sort">
							<div class="intro-text">业态特点：是卡丁车所有场馆类别中最常见的模式<br>成功案例：湖北黄石店、成都龙湖店</div>
						</div>
					</div>
					<div class="flex1 margin-top30 row-me center-all width100Per">
						<el-image :src="jing1" :preview-src-list="[jing1]" fit="cover" hide-on-click-modal
							class="img-jing1"></el-image>
					</div>
				</div>
				<div class="column-me width100Per margin-top50">
					<div class="column-me">
						<div class="column-me">
							<el-image :src="jing2" :preview-src-list="[jing2]" fit="cover" hide-on-click-modal
								class="img-jing2 bottom-yellow"></el-image>
							<el-image :src="jing3" :preview-src-list="[jing3]" fit="cover" hide-on-click-modal
								class="img-jing3 bottom-yellow margin-top20"></el-image>
						</div>
						<el-image :src="jing7" :preview-src-list="[jing7]" fit="cover" hide-on-click-modal
							class="img-jing7 bottom-yellow margin-top20"></el-image>
					</div>
					<div class="column-me margin-top20">
						<el-image :src="jing4" :preview-src-list="[jing4]" fit="cover" hide-on-click-modal
							class="img-jing4 bottom-yellow"></el-image>
						<el-image :src="jing5" :preview-src-list="[jing5]" fit="cover" hide-on-click-modal
							class="img-jing5 bottom-yellow margin-top20"></el-image>
						<el-image :src="jing6" :preview-src-list="[jing6]" fit="cover" hide-on-click-modal
							class="img-jing6 bottom-yellow margin-top20"></el-image>
					</div>
				</div>
			</div>
			<!-- 运动公园 -->
			<div class="column-me row-center" v-if="tabIndex===1">
				<div class="row-me center-all margin-top90">
					<img src="../assets/img/title_line.png" class="img-line">
					<div class="column-me row-center margin-left10 margin-right10">
						<div class="title">运动公园</div>
					</div>
					<img src="../assets/img/title_line.png" class="img-line">
				</div>
				<div class="subtitle">SPORT PARK</div>
				<div class="column-me row-center width100Per margin-top50">
					<div class="column-me flex1">
						<div class="title11">项目介绍</div>
						<div class="title22">AN INTRODUCTION TO THE PROJECT</div>
						<div class="row-me row-center margin-bottom20">
							<div class="line-yellow1"></div>
							<div class="line-yellow2"></div>
						</div>
						<div class="row-me margin-top10">
							<img src="../assets/img/1.png" class="img-sort">
							<div class="intro-text">客户群体：运动公园是面向3--12岁儿童开放的综合性娱乐馆</div>
						</div>
						<div class="row-me margin-top10">
							<img src="../assets/img/2.png" class="img-sort">
							<div class="intro-text">经营业态：场馆经营内容通常除卡丁车外还有其他的如：淘气堡、蹦床、拓展、电玩等多个经营项目</div>
						</div>
						<div class="row-me margin-top10">
							<img src="../assets/img/3.png" class="img-sort">
							<div class="intro-text">场馆位置：场馆多数建在商业综合体内，建馆面积通常在1000平以上</div>
						</div>
						<div class="row-me margin-top10">
							<img src="../assets/img/4.png" class="img-sort">
							<div class="intro-text">业态特点：经营业态多，客流容纳量大的特点<br>成功案例：成都红星美凯龙店、菏泽大润发店</div>
						</div>
					</div>
					<div class="flex1 margin-top50 row-me center-all width100Per">
						<el-image :src="zhu1" :preview-src-list="[zhu1]" fit="cover" hide-on-click-modal class="flex1">
						</el-image>
					</div>
				</div>
				<div class="column-me width100Per margin-top50">
					<div class="column-me">
						<el-image :src="zhu2" :preview-src-list="[zhu2]" fit="cover" hide-on-click-modal class="flex3">
						</el-image>
						<el-image :src="zhu3" :preview-src-list="[zhu3]" fit="cover" hide-on-click-modal
							class="flex2 margin-top20"></el-image>
					</div>
					<div class="column-me margin-top20">
						<el-image :src="zhu4" :preview-src-list="[zhu4]" fit="cover" hide-on-click-modal
							class="flex1 bottom-yellow">
						</el-image>
						<el-image :src="zhu5" :preview-src-list="[zhu5]" fit="cover" hide-on-click-modal
							class="flex1 margin-top20 bottom-yellow"></el-image>
						<el-image :src="zhu6" :preview-src-list="[zhu6]" fit="cover" hide-on-click-modal
							class="flex1 margin-top20 bottom-yellow"></el-image>
						<el-image :src="zhu7" :preview-src-list="[zhu7]" fit="cover" hide-on-click-modal
							class="img-zhu7 margin-top20 bottom-yellow"></el-image>
					</div>
				</div>
			</div>
			<!-- 室外运动场 -->
			<div class="column-me row-center" v-if="tabIndex===2">
				<div class="row-me center-all margin-top90">
					<img src="../assets/img/title_line.png" class="img-line">
					<div class="column-me row-center margin-left10 margin-right10">
						<div class="title">卡丁车室外运动场</div>
					</div>
					<img src="../assets/img/title_line.png" class="img-line">
				</div>
				<div class="subtitle">GO-KART OUTDOOR PLAYGROUND</div>
				<div class="column-me row-center width100Per margin-top50">
					<div class="column-me flex1">
						<div class="title11">项目介绍</div>
						<div class="title22">AN INTRODUCTION TO THE PROJECT</div>
						<div class="row-me row-center margin-bottom20">
							<div class="line-yellow1"></div>
							<div class="line-yellow2"></div>
						</div>
						<div class="row-me margin-top10">
							<img src="../assets/img/1.png" class="img-sort">
							<div class="intro-text">客户群体：卡丁车室外运动场是面向3岁以上儿童开放的卡丁车体验场地，客户年龄层从三岁以上可以延伸到成人</div>
						</div>
						<div class="row-me margin-top10">
							<img src="../assets/img/2.png" class="img-sort">
							<div class="intro-text">经营业态：卡丁车室外运动场主要经营以卡丁车驾驶体验为主的相关业态</div>
						</div>
						<div class="row-me margin-top10">
							<img src="../assets/img/3.png" class="img-sort">
							<div class="intro-text">运动场位置：主要建在商业综合体的外广场或客流量大的景区内，面积300--1000不等</div>
						</div>
						<div class="row-me margin-top10">
							<img src="../assets/img/4.png" class="img-sort">
							<div class="intro-text">业态特点：一般有场地面积广、展示效果好、客流容纳量大的特点<br>成功案例：北京十渡景区店、大连发现王国店</div>
						</div>
					</div>
					<div class="flex1 margin-top50 row-me center-all width100Per">
						<el-image :src="hu1" :preview-src-list="[hu1]" fit="cover" hide-on-click-modal class="flex1">
						</el-image>
					</div>
				</div>
				<div class="column-me width100Per margin-top50">
					<div class="column-me">
						<el-image :src="hu2" :preview-src-list="[hu2]" fit="cover" hide-on-click-modal
							class="flex1 bottom-yellow">
						</el-image>
						<el-image :src="hu3" :preview-src-list="[hu3]" fit="cover" hide-on-click-modal
							class="flex1 margin-top20 bottom-yellow"></el-image>
						<el-image :src="hu4" :preview-src-list="[hu4]" fit="cover" hide-on-click-modal
							class="flex1 margin-top20 bottom-yellow">
						</el-image>
					</div>
					<div class="column-me margin-top20">
						<el-image :src="hu5" :preview-src-list="[hu5]" fit="cover" hide-on-click-modal
							class="flex1 bottom-yellow">
						</el-image>
						<el-image :src="hu6" :preview-src-list="[hu6]" fit="cover" hide-on-click-modal
							class="flex1 margin-top20 bottom-yellow"></el-image>
					</div>
				</div>
			</div>
			<!-- 教培馆 -->
			<div class="column-me row-center" v-if="tabIndex===3">
				<div class="row-me center-all margin-top90">
					<img src="../assets/img/title_line.png" class="img-line">
					<div class="column-me row-center margin-left10 margin-right10">
						<div class="title">卡丁车教培馆</div>
					</div>
					<img src="../assets/img/title_line.png" class="img-line">
				</div>
				<div class="subtitle">GO-KART TEACHING HALL</div>
				<div class="column-me row-center width100Per margin-top50">
					<div class="column-me flex1">
						<div class="title11">项目介绍</div>
						<div class="title22">AN INTRODUCTION TO THE PROJECT</div>
						<div class="row-me row-center margin-bottom20">
							<div class="line-yellow1"></div>
							<div class="line-yellow2"></div>
						</div>
						<div class="row-me margin-top10">
							<img src="../assets/img/1.png" class="img-sort">
							<div class="intro-text">客户群体：卡丁车教培馆是面向3--12岁儿童开放的教培性质场馆</div>
						</div>
						<div class="row-me margin-top10">
							<img src="../assets/img/2.png" class="img-sort">
							<div class="intro-text">经营业态：卡丁车教培课程为主，卡丁车运动体验相关业态为辅</div>
						</div>
						<div class="row-me margin-top10">
							<img src="../assets/img/3.png" class="img-sort">
							<div class="intro-text">运动场位置：主要建在商业综合体内或者教培综合体内，面积一般300--500平不等</div>
						</div>
						<div class="row-me margin-top10">
							<img src="../assets/img/4.png" class="img-sort">
							<div class="intro-text">业态特点：教培和娱乐的充分融合，具有好招生，客单价高的特点<br>成功案例：西安益田假日店、宁晋家乐园天一广场店</div>
						</div>
					</div>
					<div class="flex1 margin-top50 row-me center-all width100Per">
						<el-image :src="pei1" :preview-src-list="[pei1]" fit="cover" hide-on-click-modal class="flex1">
						</el-image>
					</div>
				</div>
				<div class="column-me row-center width100Per margin-top50">
					<div class="width100Per">
						<el-image :src="pei2" :preview-src-list="[pei2]" fit="cover" hide-on-click-modal
							class="width100Per">
						</el-image>
					</div>
					<div class="column-me margin-top20 width100Per">
						<el-image :src="pei3" :preview-src-list="[pei3]" fit="cover" hide-on-click-modal
							class="margin-top20 bottom-yellow"></el-image>
						<el-image :src="pei4" :preview-src-list="[pei4]" fit="cover" hide-on-click-modal
							class="margin-top20 bottom-yellow">
						</el-image>
						<el-image :src="pei5" :preview-src-list="[pei5]" fit="cover" hide-on-click-modal
							class="margin-top20 bottom-yellow">
						</el-image>
					</div>
				</div>
			</div>
			<!-- 体能拓展馆 -->
			<div class="column-me row-center" v-if="tabIndex===4">
				<div class="row-me center-all margin-top90">
					<img src="../assets/img/title_line.png" class="img-line">
					<div class="column-me row-center margin-left10 margin-right10">
						<div class="title">卡丁车体能拓展馆</div>
					</div>
					<img src="../assets/img/title_line.png" class="img-line">
				</div>
				<div class="subtitle">GO-KARTS CAN EXPAND THE MUSEUM</div>
				<div class="column-me row-center width100Per margin-top50">
					<div class="column-me flex1">
						<div class="title11">项目介绍</div>
						<div class="title22">AN INTRODUCTION TO THE PROJECT</div>
						<div class="row-me row-center margin-bottom20">
							<div class="line-yellow1"></div>
							<div class="line-yellow2"></div>
						</div>
						<div class="row-me margin-top10">
							<img src="../assets/img/1.png" class="img-sort">
							<div class="intro-text">客户群体：卡丁车体能拓展馆是面向3--12岁儿童开放的综合性教培场馆</div>
						</div>
						<div class="row-me margin-top10">
							<img src="../assets/img/2.png" class="img-sort">
							<div class="intro-text">经营业态：体能拓展教育，卡丁车教培课，卡丁车运动体验等相关业态</div>
						</div>
						<div class="row-me margin-top10">
							<img src="../assets/img/3.png" class="img-sort">
							<div class="intro-text">运动场位置：主要建在商业综合体内或者教培综合体内，面积一般500--1000平不等</div>
						</div>
						<div class="row-me margin-top10">
							<img src="../assets/img/4.png" class="img-sort">
							<div class="intro-text">业态特点：教育综合性强，收费形式广的特点<br>成功案例：绵阳涪城万达店、梅州店</div>
						</div>
					</div>
					<div class="flex1 margin-top50 row-me center-all width100Per">
						<el-image :src="ti1" :preview-src-list="[ti1]" fit="cover" hide-on-click-modal
							class="width100Per">
						</el-image>
					</div>
				</div>
				<div class="column-me width100Per margin-top50">
					<div class="column-me width100Per">
						<el-image :src="ti2" :preview-src-list="[ti2]" fit="cover" hide-on-click-modal
							class="flex1 bottom-yellow">
						</el-image>
						<el-image :src="ti3" :preview-src-list="[ti3]" fit="cover" hide-on-click-modal
							class="flex1 margin-top20 bottom-yellow">
						</el-image>
						<el-image :src="ti4" :preview-src-list="[ti4]" fit="cover" hide-on-click-modal
							class="flex1 margin-top20 bottom-yellow">
						</el-image>
					</div>
					<div class="column-me width100Per margin-top20">
						<el-image :src="ti5" :preview-src-list="[ti5]" fit="cover" hide-on-click-modal
							class="flex1 bottom-yellow">
						</el-image>
						<el-image :src="ti6" :preview-src-list="[ti6]" fit="cover" hide-on-click-modal
							class="flex1 margin-top20 bottom-yellow">
						</el-image>
						<el-image :src="ti7" :preview-src-list="[ti7]" fit="cover" hide-on-click-modal
							class="flex1 margin-top20 bottom-yellow">
						</el-image>
					</div>
				</div>
			</div>
		</div>
	</div>

	<div class="sport-park" v-else>
		<img src="../assets/img/ydgy.png" class="img-banner">
		<div class="main-content">
			<div class="row-me row-center tab-div">
				<div class="row-me center-all left-div">
					<div class="title1">卡丁车</div>
					<div class="title2 margin-left20">GO-KART</div>
				</div>
				<div class="row-me row-center flex-end flex1 padding-right30">
					<div class="title0" :class="{'title0-active':tabIndex===0}" @click="clickIndex(0)">竞速馆</div>
					<div class="tab-line"></div>
					<div class="title0" :class="{'title0-active':tabIndex===1}" @click="clickIndex(1)">运动公园</div>
					<div class="tab-line"></div>
					<div class="title0" :class="{'title0-active':tabIndex===2}" @click="clickIndex(2)">室外运动场</div>
<!--					<div class="tab-line"></div>-->
<!--					<div class="title0" :class="{'title0-active':tabIndex===3}" @click="clickIndex(3)">教培馆</div>-->
<!--					<div class="tab-line"></div>-->
<!--					<div class="title0" :class="{'title0-active':tabIndex===4}" @click="clickIndex(4)">体能拓展馆</div>-->
				</div>
			</div>
			<!-- 竞速馆 -->
			<div class="column-me row-center" v-if="tabIndex===0">
				<div class="row-me center-all margin-top90">
					<img src="../assets/img/title_line.png" class="img-line">
					<div class="column-me row-center margin-left60 margin-right60">
						<div class="title">卡丁车室内竞速馆</div>
						<div class="subtitle">GO-KART INDOOR RACE HALL</div>
					</div>
					<img src="../assets/img/title_line.png" class="img-line">
				</div>
				<div class="row-me row-center width100Per margin-top100">
					<div class="column-me flex1">
						<div class="title11">项目介绍</div>
						<div class="title22">AN INTRODUCTION TO THE PROJECT</div>
						<div class="row-me row-center margin-bottom20">
							<div class="line-yellow1"></div>
							<div class="line-yellow2"></div>
						</div>
						<div class="row-me margin-top10">
							<img src="../assets/img/1.png" class="img-sort">
							<div class="intro-text">客户群体：室内卡丁车竞速馆是面向3--12岁儿童开放的单一卡丁车运动体验馆</div>
						</div>
						<div class="row-me margin-top10">
							<img src="../assets/img/2.png" class="img-sort">
							<div class="intro-text">经营业态：场馆主要经营以卡丁车驾驶体验为主的相关业态，通过不同的场景道具来营造驾驶氛围，通过圈速系统来测算驾驶者的驾驶水平</div>
						</div>
						<div class="row-me margin-top10">
							<img src="../assets/img/3.png" class="img-sort">
							<div class="intro-text">场馆位置：场馆主要建在商业综合体内或者商业综合体中庭位置，建馆面积通常在300平左右</div>
						</div>
						<div class="row-me margin-top10">
							<img src="../assets/img/4.png" class="img-sort">
							<div class="intro-text">业态特点：是卡丁车所有场馆类别中最常见的模式<br>成功案例：湖北黄石店、成都龙湖店</div>
						</div>
					</div>
					<div class="flex1 margin-left25 row-me center-all">
						<el-image :src="jing1" :preview-src-list="[jing1]" fit="scale-down" hide-on-click-modal
							class="img-jing1"></el-image>
					</div>
				</div>
				<div class="row-me width100Per margin-top120">
					<div class="column-me">
						<div class="row-me">
							<el-image :src="jing2" :preview-src-list="[jing2]" fit="cover" hide-on-click-modal
								class="img-jing2 bottom-yellow"></el-image>
							<el-image :src="jing3" :preview-src-list="[jing3]" fit="cover" hide-on-click-modal
								class="img-jing3 bottom-yellow margin-left20"></el-image>
						</div>
						<el-image :src="jing7" :preview-src-list="[jing7]" fit="cover" hide-on-click-modal
							class="img-jing7 bottom-yellow margin-top20"></el-image>
					</div>
					<div class="column-me margin-left20">
						<el-image :src="jing4" :preview-src-list="[jing4]" fit="cover" hide-on-click-modal
							class="img-jing4 bottom-yellow"></el-image>
						<el-image :src="jing5" :preview-src-list="[jing5]" fit="cover" hide-on-click-modal
							class="img-jing5 bottom-yellow margin-top20"></el-image>
						<el-image :src="jing6" :preview-src-list="[jing6]" fit="cover" hide-on-click-modal
							class="img-jing6 bottom-yellow margin-top20"></el-image>
					</div>
				</div>
			</div>
			<!-- 运动公园 -->
			<div class="column-me row-center" v-if="tabIndex===1">
				<div class="row-me center-all margin-top90">
					<img src="../assets/img/title_line.png" class="img-line">
					<div class="column-me row-center margin-left60 margin-right60">
						<div class="title">运动公园</div>
						<div class="subtitle">SPORT PARK</div>
					</div>
					<img src="../assets/img/title_line.png" class="img-line">
				</div>
				<div class="row-me row-center width100Per margin-top100">
					<div class="column-me flex1">
						<div class="title11">项目介绍</div>
						<div class="title22">AN INTRODUCTION TO THE PROJECT</div>
						<div class="row-me row-center margin-bottom20">
							<div class="line-yellow1"></div>
							<div class="line-yellow2"></div>
						</div>
						<div class="row-me margin-top10">
							<img src="../assets/img/1.png" class="img-sort">
							<div class="intro-text">客户群体：运动公园是面向3--12岁儿童开放的综合性娱乐馆</div>
						</div>
						<div class="row-me margin-top10">
							<img src="../assets/img/2.png" class="img-sort">
							<div class="intro-text">经营业态：场馆经营内容通常除卡丁车外还有其他的如：淘气堡、蹦床、拓展、电玩等多个经营项目</div>
						</div>
						<div class="row-me margin-top10">
							<img src="../assets/img/3.png" class="img-sort">
							<div class="intro-text">场馆位置：场馆多数建在商业综合体内，建馆面积通常在1000平以上</div>
						</div>
						<div class="row-me margin-top10">
							<img src="../assets/img/4.png" class="img-sort">
							<div class="intro-text">业态特点：经营业态多，客流容纳量大的特点<br>成功案例：成都红星美凯龙店、菏泽大润发店</div>
						</div>
					</div>
					<div class="flex1 margin-left25 row-me center-all">
						<el-image :src="zhu1" :preview-src-list="[zhu1]" fit="scale-down" hide-on-click-modal>
						</el-image>
					</div>
				</div>
				<div class="column-me width100Per margin-top120">
					<div class="row-me">
						<el-image :src="zhu2" :preview-src-list="[zhu2]" fit="scale-down" hide-on-click-modal
							class="flex3"></el-image>
						<el-image :src="zhu3" :preview-src-list="[zhu3]" fit="scale-down" hide-on-click-modal
							class="flex2 margin-left20"></el-image>
					</div>
					<div class="row-me margin-top50">
						<el-image :src="zhu4" :preview-src-list="[zhu4]" fit="cover" hide-on-click-modal
							class="flex1 bottom-yellow">
						</el-image>
						<el-image :src="zhu5" :preview-src-list="[zhu5]" fit="cover" hide-on-click-modal
							class="flex1 margin-left20 bottom-yellow"></el-image>
						<el-image :src="zhu6" :preview-src-list="[zhu6]" fit="cover" hide-on-click-modal
							class="flex1 margin-left20 bottom-yellow"></el-image>
						<el-image :src="zhu7" :preview-src-list="[zhu7]" fit="cover" hide-on-click-modal
							class="img-zhu7 margin-left20 bottom-yellow"></el-image>
					</div>
				</div>
			</div>
			<!-- 室外运动场 -->
			<div class="column-me row-center" v-if="tabIndex===2">
				<div class="row-me center-all margin-top90">
					<img src="../assets/img/title_line.png" class="img-line">
					<div class="column-me row-center margin-left60 margin-right60">
						<div class="title">卡丁车室外运动场</div>
						<div class="subtitle">GO-KART OUTDOOR PLAYGROUND</div>
					</div>
					<img src="../assets/img/title_line.png" class="img-line">
				</div>
				<div class="row-me row-center width100Per margin-top100">
					<div class="column-me flex1">
						<div class="title11">项目介绍</div>
						<div class="title22">AN INTRODUCTION TO THE PROJECT</div>
						<div class="row-me row-center margin-bottom20">
							<div class="line-yellow1"></div>
							<div class="line-yellow2"></div>
						</div>
						<div class="row-me margin-top10">
							<img src="../assets/img/1.png" class="img-sort">
							<div class="intro-text">客户群体：卡丁车室外运动场是面向3岁以上儿童开放的卡丁车体验场地，客户年龄层从三岁以上可以延伸到成人</div>
						</div>
						<div class="row-me margin-top10">
							<img src="../assets/img/2.png" class="img-sort">
							<div class="intro-text">经营业态：卡丁车室外运动场主要经营以卡丁车驾驶体验为主的相关业态</div>
						</div>
						<div class="row-me margin-top10">
							<img src="../assets/img/3.png" class="img-sort">
							<div class="intro-text">运动场位置：主要建在商业综合体的外广场或客流量大的景区内，面积300--1000不等</div>
						</div>
						<div class="row-me margin-top10">
							<img src="../assets/img/4.png" class="img-sort">
							<div class="intro-text">业态特点：一般有场地面积广、展示效果好、客流容纳量大的特点<br>成功案例：北京十渡景区店、大连发现王国店</div>
						</div>
					</div>
					<div class="flex1 margin-left25 row-me center-all">
						<el-image :src="hu1" :preview-src-list="[hu1]" fit="scale-down" hide-on-click-modal>
						</el-image>
					</div>
				</div>
				<div class="column-me width100Per margin-top120">
					<div class="row-me">
						<el-image :src="hu2" :preview-src-list="[hu2]" fit="cover" hide-on-click-modal
							class="flex1 bottom-yellow">
						</el-image>
						<el-image :src="hu3" :preview-src-list="[hu3]" fit="cover" hide-on-click-modal
							class="flex1 margin-left20 bottom-yellow"></el-image>
						<el-image :src="hu4" :preview-src-list="[hu4]" fit="cover" hide-on-click-modal
							class="flex1 margin-left20 bottom-yellow">
						</el-image>
					</div>
					<div class="row-me margin-top20">
						<el-image :src="hu5" :preview-src-list="[hu5]" fit="cover" hide-on-click-modal
							class="flex1 bottom-yellow">
						</el-image>
						<el-image :src="hu6" :preview-src-list="[hu6]" fit="cover" hide-on-click-modal
							class="flex1 margin-left20 bottom-yellow"></el-image>
					</div>
				</div>
			</div>
			<!-- 教培馆 -->
			<div class="column-me row-center" v-if="tabIndex===3">
				<div class="row-me center-all margin-top90">
					<img src="../assets/img/title_line.png" class="img-line">
					<div class="column-me row-center margin-left60 margin-right60">
						<div class="title">卡丁车教培馆</div>
						<div class="subtitle">GO-KART TEACHING HALL</div>
					</div>
					<img src="../assets/img/title_line.png" class="img-line">
				</div>
				<div class="row-me row-center width100Per margin-top100">
					<div class="column-me flex1">
						<div class="title11">项目介绍</div>
						<div class="title22">AN INTRODUCTION TO THE PROJECT</div>
						<div class="row-me row-center margin-bottom20">
							<div class="line-yellow1"></div>
							<div class="line-yellow2"></div>
						</div>
						<div class="row-me margin-top10">
							<img src="../assets/img/1.png" class="img-sort">
							<div class="intro-text">客户群体：卡丁车教培馆是面向3--12岁儿童开放的教培性质场馆</div>
						</div>
						<div class="row-me margin-top10">
							<img src="../assets/img/2.png" class="img-sort">
							<div class="intro-text">经营业态：卡丁车教培课程为主，卡丁车运动体验相关业态为辅</div>
						</div>
						<div class="row-me margin-top10">
							<img src="../assets/img/3.png" class="img-sort">
							<div class="intro-text">运动场位置：主要建在商业综合体内或者教培综合体内，面积一般300--500平不等</div>
						</div>
						<div class="row-me margin-top10">
							<img src="../assets/img/4.png" class="img-sort">
							<div class="intro-text">业态特点：教培和娱乐的充分融合，具有好招生，客单价高的特点<br>成功案例：西安益田假日店、宁晋家乐园天一广场店</div>
						</div>
					</div>
					<div class="flex1 margin-left25 row-me center-all">
						<el-image :src="pei1" :preview-src-list="[pei1]" fit="scale-down" hide-on-click-modal>
						</el-image>
					</div>
				</div>
				<div class="row-me width100Per margin-top120">
					<div class="flex2">
						<el-image :src="pei2" :preview-src-list="[pei2]" fit="scale-down" hide-on-click-modal>
						</el-image>
					</div>
					<div class="flex1 column-me margin-left20">
						<el-image :src="pei3" :preview-src-list="[pei3]" fit="cover" hide-on-click-modal
							class="margin-top20 bottom-yellow"></el-image>
						<el-image :src="pei4" :preview-src-list="[pei4]" fit="cover" hide-on-click-modal
							class="margin-top20 bottom-yellow">
						</el-image>
						<el-image :src="pei5" :preview-src-list="[pei5]" fit="cover" hide-on-click-modal
							class="margin-top20 bottom-yellow">
						</el-image>
					</div>
				</div>
			</div>
			<!-- 体能拓展馆 -->
			<div class="column-me row-center" v-if="tabIndex===4">
				<div class="row-me center-all margin-top90">
					<img src="../assets/img/title_line.png" class="img-line">
					<div class="column-me row-center margin-left60 margin-right60">
						<div class="title">卡丁车体能拓展馆</div>
						<div class="subtitle">GO-KARTS CAN EXPAND THE MUSEUM</div>
					</div>
					<img src="../assets/img/title_line.png" class="img-line">
				</div>
				<div class="row-me row-center width100Per margin-top100">
					<div class="column-me flex1">
						<div class="title11">项目介绍</div>
						<div class="title22">AN INTRODUCTION TO THE PROJECT</div>
						<div class="row-me row-center margin-bottom20">
							<div class="line-yellow1"></div>
							<div class="line-yellow2"></div>
						</div>
						<div class="row-me margin-top10">
							<img src="../assets/img/1.png" class="img-sort">
							<div class="intro-text">客户群体：卡丁车体能拓展馆是面向3--12岁儿童开放的综合性教培场馆</div>
						</div>
						<div class="row-me margin-top10">
							<img src="../assets/img/2.png" class="img-sort">
							<div class="intro-text">经营业态：体能拓展教育，卡丁车教培课，卡丁车运动体验等相关业态</div>
						</div>
						<div class="row-me margin-top10">
							<img src="../assets/img/3.png" class="img-sort">
							<div class="intro-text">运动场位置：主要建在商业综合体内或者教培综合体内，面积一般500--1000平不等</div>
						</div>
						<div class="row-me margin-top10">
							<img src="../assets/img/4.png" class="img-sort">
							<div class="intro-text">业态特点：教育综合性强，收费形式广的特点<br>成功案例：绵阳涪城万达店、梅州店</div>
						</div>
					</div>
					<div class="flex1 margin-left25 row-me center-all">
						<el-image :src="ti1" :preview-src-list="[ti1]" fit="scale-down" hide-on-click-modal>
						</el-image>
					</div>
				</div>
				<div class="column-me width100Per margin-top120">
					<div class="row-me row-center">
						<el-image :src="ti2" :preview-src-list="[ti2]" fit="cover" hide-on-click-modal
							class="flex1 bottom-yellow">
						</el-image>
						<el-image :src="ti3" :preview-src-list="[ti3]" fit="cover" hide-on-click-modal
							class="flex1 margin-left20 bottom-yellow">
						</el-image>
						<el-image :src="ti4" :preview-src-list="[ti4]" fit="cover" hide-on-click-modal
							class="flex1 margin-left20 bottom-yellow">
						</el-image>
					</div>
					<div class="row-me row-center margin-top20">
						<el-image :src="ti5" :preview-src-list="[ti5]" fit="cover" hide-on-click-modal
							class="flex1 bottom-yellow">
						</el-image>
						<el-image :src="ti6" :preview-src-list="[ti6]" fit="cover" hide-on-click-modal
							class="flex1 margin-left20 bottom-yellow">
						</el-image>
						<el-image :src="ti7" :preview-src-list="[ti7]" fit="cover" hide-on-click-modal
							class="flex1 margin-left20 bottom-yellow">
						</el-image>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		components: {

		},
		data() {
			return {
				tabIndex: 0,
				jing1: require('../assets/img/jing1.jpg'),
				jing2: require('../assets/img/jing2.jpg'),
				jing3: require('../assets/img/jing3.jpg'),
				jing4: require('../assets/img/jing4.jpg'),
				jing5: require('../assets/img/jing5.jpg'),
				jing6: require('../assets/img/jing6.jpg'),
				jing7: require('../assets/img/jing7.jpg'),
				zhu1: require('../assets/img/zhu1.png'),
				zhu2: require('../assets/img/zhu2.png'),
				zhu3: require('../assets/img/zhu3.png'),
				zhu4: require('../assets/img/zhu4.png'),
				zhu5: require('../assets/img/zhu5.png'),
				zhu6: require('../assets/img/zhu6.png'),
				zhu7: require('../assets/img/zhu7.png'),
				hu1: require('../assets/img/hu1.jpg'),
				hu2: require('../assets/img/hu2.jpg'),
				hu3: require('../assets/img/hu3.jpg'),
				hu4: require('../assets/img/hu4.jpg'),
				hu5: require('../assets/img/hu5.jpg'),
				hu6: require('../assets/img/hu6.jpg'),
				pei1: require('../assets/img/pei1.png'),
				pei2: require('../assets/img/pei2.png'),
				pei3: require('../assets/img/pei3.png'),
				pei4: require('../assets/img/pei4.png'),
				pei5: require('../assets/img/pei5.jpg'),
				ti1: require('../assets/img/ti1.png'),
				ti2: require('../assets/img/ti2.jpg'),
				ti3: require('../assets/img/ti3.png'),
				ti4: require('../assets/img/ti4.png'),
				ti5: require('../assets/img/ti5.jpg'),
				ti6: require('../assets/img/ti6.png'),
				ti7: require('../assets/img/ti7.png'),
				isMobile: false,
			}
		},
		// html加载完成之前
		created() {

		},
		// html加载完成后执行。
		mounted() {
			this.isMobile = sessionStorage.getItem('mobile') === '1'
		},
		// 事件方法执行
		methods: {
			clickIndex(index) {
				this.$router.push({
					path: '/sport_park',
					query: {
						index: index
					}
				})
			},
		},
		// 计算属性
		computed: {},
		// 侦听器
		watch: {
			$route: {
				handler(to, from) {
					if (to.name.indexOf('sport_park') !== -1) {
						if (this.$route.query.index) {
							this.tabIndex = Number(this.$route.query.index)
						}
					}
				},
				deep: true
			},
		},
		activated() {
			if (this.$route.query.index) {
				this.tabIndex = Number(this.$route.query.index)
			}
		}
	}
</script>

<style lang="scss" scoped>
	.sport-park-mobile {
		.img-banner {
			width: 100%;
			object-fit: cover;
			pointer-events: none;
		}

		.main-content {
			padding: 20px;

			.tab-div {
				background: #F4F4F4;
				border-radius: 10px;

				.left-div {
					width: 130px;
					background: #000000;
					border-radius: 10px 0px 0px 10px;
					padding: 10px 0;

					.title1 {
						font-size: 23px;
						color: #FFFFFF;
					}

					.title2 {
						font-size: 15px;
						color: #FFF10C;
						margin-top: 10px;
					}
				}

				.title0 {
					font-size: 20px;
					color: #999999;
					text-align: center;
					padding: 0 10px;
					cursor: pointer;

					&:hover {
						font-weight: bold;
					}

					&-active {
						color: #666666;
						font-weight: bold;
					}
				}

				.tab-line {
					width: 3px;
					height: 20px;
					background: #999999;
					border-radius: 10px;
				}
			}

			.img-line {
				width: 50px;
			}

			.title {
				font-size: 23px;
				color: #000000;
				letter-spacing: 2px;
				margin-bottom: 10px;
				text-align: center;
			}

			.subtitle {
				font-size: 18px;
				color: #666666;
			}

			.title11 {
				font-size: 28px;
				color: #000000;
			}

			.title22 {
				font-size: 16px;
				color: #000000;
				margin-top: 10px;
			}

			.line-yellow1 {
				width: 212px;
				height: 5px;
				background: #FFE100;
				margin-top: 5px;
			}

			.line-yellow2 {
				width: 31px;
				height: 5px;
				background: #FFE100;
				margin-left: 10px;
				margin-top: 5px;
			}

			.img-sort {
				width: 35px;
				height: 35px;
			}

			.intro-text {
				font-size: 20px;
				color: #000000;
				margin-left: 12px;
				line-height: 30px;
				flex: 1;
			}

			.bottom-yellow {
				border-bottom: 10px solid #FFE100;
			}

			.img-jing1 {
				width: 100%;
			}

			.img-jing2 {
				width: 100%;
			}

			.img-jing3 {
				width: 100%;
			}

			.img-jing4 {
				width: 100%;
			}

			.img-jing5 {
				width: 100%;
			}

			.img-jing6 {
				width: 100%;
			}

			.img-jing7 {
				width: 100%;
			}
		}
	}

	.sport-park {
		.img-banner {
			width: 100%;
			object-fit: cover;
			pointer-events: none;
		}

		.main-content {
			padding: 80px 100px;

			.tab-div {
				height: 100px;
				background: #F4F4F4;
				border-radius: 10px;

				.left-div {
					width: 330px;
					height: 100px;
					background: #000000;
					border-radius: 10px 0px 0px 10px;

					.title1 {
						font-size: 30px;
						color: #FFFFFF;
					}

					.title2 {
						font-size: 18px;
						color: #FFF10C;
						margin-bottom: -15px;
					}
				}

				.title0 {
					font-size: 26px;
					color: #999999;
					text-align: center;
					padding: 0 30px;
					cursor: pointer;

					&:hover {
						font-weight: bold;
					}

					&-active {
						color: #666666;
						font-weight: bold;
					}
				}

				.tab-line {
					width: 3px;
					height: 30px;
					background: #999999;
					border-radius: 10px;
				}
			}

			.img-line {
				width: 145px;
				object-fit: scale-down;
				margin-bottom: 10px;
			}

			.title {
				font-size: 48px;
				color: #000000;
				letter-spacing: 5px;
			}

			.subtitle {
				font-size: 24px;
				color: #666666;
			}

			.title11 {
				font-size: 36px;
				color: #000000;
			}

			.title22 {
				font-size: 16px;
				color: #000000;
				margin-top: 10px;
			}

			.line-yellow1 {
				width: 212px;
				height: 5px;
				background: #FFE100;
				margin-top: 5px;
			}

			.line-yellow2 {
				width: 31px;
				height: 5px;
				background: #FFE100;
				margin-left: 10px;
				margin-top: 5px;
			}

			.img-sort {
				width: 40px;
				height: 40px;
				object-fit: scale-down;
			}

			.intro-text {
				font-size: 24px;
				color: #000000;
				margin-left: 12px;
				line-height: 45px;
			}

			.bottom-yellow {
				border-bottom: 10px solid #FFE100;
			}

			.img-jing1 {
				width: 720px;
				height: 435px;
			}

			.img-jing2 {
				width: 588px;
				height: 420px;
			}

			.img-jing3 {
				width: 588px;
				height: 420px;
			}

			.img-jing4 {
				width: 509px;
				height: 283px;
			}

			.img-jing5 {
				width: 509px;
				height: 283px;
			}

			.img-jing6 {
				width: 509px;
				height: 190px;
			}

			.img-jing7 {
				width: 1195px;
				height: 366px;
			}
		}
	}
</style>
